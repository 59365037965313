import React, { useContext, useEffect, useState } from 'react'

import locales from '../constants/locales'
import StoreContext from '../context/StoreContext'

function useSingleton(data) {
  const context = useContext(StoreContext)
  const locale = locales[context.language === 'root' ? 'au' : context.language]

  const findLocale = () => {
    let localeData = data['edges'].find(
      (el) => el['node']['lang'] === locale.content
    )
    localeData = localeData?.node

    let objectKeys = Object.keys(localeData)
    let singletonData = {}
    for (let singletonKey of objectKeys) {
      const singletonValue = localeData[singletonKey]
      if (typeof singletonValue === 'object') {
        singletonData[singletonKey] = singletonValue.value
      } else {
        singletonData[singletonKey] = singletonValue
      }
    }
    return singletonData
  }
  const [singletonData, setSingletonData] = useState(findLocale())

  return singletonData
}

export default useSingleton
