import styled from '@emotion/styled'
import React from 'react'
import InViewMonitor from 'react-inview-monitor'

import InlineVideo from '../../components/InlineVideo'

// Global

const Fade = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeIn ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

// Campaign Image
const CampaignImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1025px) {
    /* display: none; */
  }
`

const CampaignSection = ({ name, namePhoto }) => {
  return (
    <Fade>
      <CampaignImageContainer>
        <InlineVideo
          source={`/${name}.mp4`}
          posterSrc={
            namePhoto === undefined ? `/${name}.jpg` : `/${namePhoto}.jpg`
          }
        />
      </CampaignImageContainer>
    </Fade>
  )
}

export default CampaignSection
