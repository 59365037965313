import styled from '@emotion/styled'
import React, { Component, useEffect } from 'react'

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 32px;
  @media (max-width: 600px) {
    margin: 12px;
  }
`

const OkendoReviewsWidget = ({ productId }) => {
  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        document.onclick = (e) => {
          e = e || window.event
          var element = e.target
          if (
            element.tagName == 'A' &&
            element.className ===
              'okeReviews-review-reviewer-product-details-link'
          ) {
            const redirects = {
              '/products/backpack': '/travel-bags/carry-all-backpack/',
              '/products/carry-all-daypack': '/travel-bags/carry-all-daypack/',
              '/products/carry-all-pocket': '/travel-bags/carry-all-pocket/',
              '/products/carry-all-sling': '/travel-bags/carry-all-sling/',
              '/products/tote': '/travel-bags/carry-all-tote/',
              '/products/weekender-1':
                '/travel-bags/carry-all-weekender-duffle-bag/',
              '/products/carry-me': '/travel-bags/carry-me/',
              '/products/carry-on-light': '/luggage/carry-on-light/',
              '/products/carry-on-pro': '/luggage/carry-on-pro/',
              '/products/daybreak-backpack': '/travel-bags/daybreak-backpack/',
              '/products/daybreak-tote': '/travel-bags/daybreak-tote/',
              '/products/daybreak-weekender': '/travel-bags/daybreak-backpack/',
              '/products/pack-perfectly-packing-cells':
                '/travel-accessories/packing-cells/',
              '/products/the-perfect-carry-on/': '/luggage/carry-on/',
              '/products/the-july-checked-bag': '/luggage/checked/',
              '/products/the-july-checked-plus': '/luggage/checked-plus/',
              '/products/drink-bottle':
                '/travel-accessories/personalised-drink-bottles/',
            }
            let href = element.href.replace(
              'http://checkout.july.com',
              'https://july.com'
            )
            let afterDomain = href.replace('https://july.com', '')
            let oldUris = Object.keys(redirects)
            if (oldUris.includes(afterDomain)) {
              element.href = `https://july.com${redirects[afterDomain]}`
            }
            return true
          }
        }
      }
    }, 3000)
  }, [])

  return (
    <ReviewContainer>
      <div
        id="oke-reviews-widget"
        data-oke-reviews-widget
        data-oke-reviews-product-id={`shopify-${productId}`}
      ></div>
      <br />
    </ReviewContainer>
  )
}

// class OkendoReviewsWidget extends Component {
//   render() {
//     return (
//       <ReviewContainer>
//         <div
//           id="oke-reviews-widget"
//           data-oke-reviews-widget
//           data-oke-reviews-product-id={`shopify-${this.props.productId}`}
//         ></div>
//         <br />
//       </ReviewContainer>
//     )
//   }

//   componentDidMount() {
//     setTimeout(() => {
//       if (typeof window !== 'undefined') {
//         if (
//           typeof window.document !== 'undefined' &&
//           typeof window.okendoInitApi !== 'undefined'
//         ) {
//           const reviewsWidget = window.document.querySelector(
//             '#oke-reviews-widget'
//           )
//           window.okendoInitApi.initReviewsWidget(reviewsWidget)

//           const reviewsWidgetStars = window.document.querySelector(
//             '#oke-reviews-widget-stars'
//           )
//           window.okendoInitApi.initReviewsWidget(reviewsWidgetStars)

//         }
//       }
//     }, 5000)
//   }
// }

export default OkendoReviewsWidget
